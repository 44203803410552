<template>
  <div class="hetong">
    <van-nav-bar :title="'kontrak'" @click-left="$router.go(-1)">
      <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <table class="gridtable">
      <tr>
        <th>Nama Peminjan:</th>
        <th>{{ name }}</th>
      </tr>
      <tr>
        <th>Nomor Kontrak:</th>
        <th>{{ id }}</th>
      </tr>
      <tr>
        <th>Tanggal Pinjaman:</th>
        <th>{{ getZoneTimenum }}</th>
      </tr>
      <tr>
        <th>Nomor Identitas:</th>
        <th>{{ idCardNo }}</th>
      </tr>
      <tr>
        <th>Nomor Telepon:</th>
        <th>{{ phone }}</th>
      </tr>
      <tr>
        <th>Jumlah Pinjaman (Rupiah) :</th>
        <th>{{ quota }}</th>
      </tr>
      <tr>
        <th>Jangka Waktu Pembayaran (Bulan) :</th>
        <th>{{ months }}</th>
      </tr>
      <tr>
        <th>Suku Bunga Bulanan:</th>
        <th>{{ rate }}</th>
      </tr>
      <tr>
        <th>Jadwal Pembayaran:</th>
        <th>1-5 haribulan</th>
      </tr>
      <tr>
        <th>Bagaimana cara melakukan peminjaman</th>
        <th class="more">
          <div>
            Peminjam wajib mengisi informasi pada sistem secara lengkap dan
            akurat , semua informasi harus diisi dengan sebenar-benarnya. Jika
            peminjam memberikan informasi yang tidak benar. Perusahaan dapat
            mengambil tindakan hukum terhadap peminjam. Semua informasi yang
            telah dikonfirmasi oleh peminjam tidak dapat diubah atau di batalkan
          </div>
          <div>
            Setelah batas kredit disetujui Peminjam harus mulai melunasi
            pinjamannya kepada perusahaan pada bulan setelah bulan pencairan
            pinjaman. Dengan membayar pokok dan Bunga dibayarkan kepada
            perusahaan dengan angsuran yang sama setiap bulannya, peminjam harus
            memasukkan uangnya ke dalam rekening tabungan yang telah dilaporkan
            kepada perusahaan setiap bulan, yaitu rekening yang sama dengan
            tempat perusahaan mentransfer pinjaman kepada peminjam. dan
            perusahaan akan memotong uang dari rekening titipan sesuai
            kesepakatan sampai utangnya dilunasi.
          </div>
          <div>
            Ketika kontrak tersebut ditandatangani Jika perusahaan menemukan
            adanya tindakan yang tidak normal maka Perusahaan berhak
            menghentikan sementara pinjaman dari peminjam .Untuk melakukan
            investigasi dan mencari solusi permasalahan. Peminjam harus memberi
            tahu perusahaan tentang ada nya ketidak beresan ini dalam waktu 3
            hari jika tidak, perusahaan berhak menolak kontrak ini . Dalam
            mempertimbangkan persetujuan pinjaman, perusahaan dapat menggunakan
            agunan. Hasil pertimbangan pinjaman bergantung pada komite peninjau
            kredit berdasarkan informasi peminjam yang dimasukkan ke dalam
            sistem dan dilaporkan kepada petugas bagian pinjaman. Apabila
            terdapat kesalahan dalam pengajuan pinjaman ini, maka kesalahan
            tersebut disebabkan oleh pihak peminjam. Peminjam harus
            memberitahukan kepada staf keuangan untuk memperbaiki kesalahannya
            sesuai pertimbangan panitia. Jika peminjam tidak mengajukan
            permintaan untuk memperbaiki kesalahan pada bagian ini. Perusahaan
            dapat mengajukan gugatan langsung ke pengadilan karena dianggap
            penipuan serius. dan akan dihukum berat menurut hukum yang berlaku
            dan menyita surat berharga peminjam , menurut hukum (segala biaya
            administrasi dalam menyelesaikan prosedur hukum harus ditanggung
            oleh peminjam).
          </div>
        </th>
      </tr>
      <tr>
        <th>3.tanggung jawab melanggar kontrak</th>
        <th>
          Peminjam berjanji jika peminjam menyetujui persyaratan kontrak. Jika
          kontrak tidak dipatuhi Perusahaan berhak mengakhiri kontrak dan
          menuntut peminjam membayar pokok dan ditambah bunga . agar di
          kembalikan ke perusahaan dan peminjam bersedia membayar ganti rugi
          dalam penagihan, biaya pengacara, berbagai biaya pengadilan yang
          berkaitan dengan litigasi serta (1) biaya lainnya (2) biaya
          pengelolaan rekening tertentu yang telah jatuh tempo; (3) bunga
          tertentu yang telah jatuh tempo; (4) biaya pengelolaan rekening
          pinjaman tertentu; (5) bunga pinjaman tertentu (6) Biaya verifikasi
          surat mendesak (7) Biaya pengelolaan rekening normal (8) Bunga normal
          (9) Manager keuangan
        </th>
      </tr>
      <tr>
        <th>Tandatangan peminjam</th>
        <th>
          <img width="200" :src="img" alt="" />
        </th>
      </tr>
      <!-- <tr>
        <th>Tanda tangan peminjam</th>
        <th>Tanda tangan pemberi pinjaman</th>
      </tr> -->
    </table>
  </div>
</template>

<script>
import './css/my.css'
import { getAutograph, getLoanAgreementInfo } from '@/api/my'
import { loanInfo } from '../../api/wallet'
export default {
  name: 'hetong',
  data () {
    return {
      // 贷款编号
      id: '',
      // 签名
      img: '',
      // 手机号
      phone: '',
      // 贷款金额
      quota: '',
      // 利率
      rate: '',
      // 姓名
      name: '',
      // 贷款期限
      months: '',
      // 身份证
      idCardNo: '',
      // 当前时间
      getZoneTimenum: ''
    }
  },
  created () {
    // this.getSign()
    this.init_getLoanAgreementInfo()
    this.getLoanId()
  },
  methods: {
    // 获取贷款合同编号
    async getLoanId () {
      const data = await loanInfo()
      this.id = data.data.id
    },
    // 获取签名
    async getSign () {
      const { data } = await getAutograph()
      this.img = `${data.data}`
    },
    // 获取签名
    async init_getLoanAgreementInfo () {
      const { data } = await getLoanAgreementInfo()
      this.img = `${data.data.autograph}`
      this.idCardNo = data.data.idCardNo
      this.months = data.data.months
      this.name = data.data.name
      this.phone = data.data.phone
      this.quota = data.data.quota
      this.rate = data.data.rate
      this.getZoneTimenum = this.getLocalTime(8)
    },
    // 获取时间
    getLocalTime (i) {
      // 参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
      if (typeof i !== 'number') return
      var d = new Date()
      // 得到1970年一月一日到现在的秒数
      var len = d.getTime()
      // 本地时间与GMT时间的时间偏移差
      var offset = d.getTimezoneOffset() * 60000
      // 得到现在的格林尼治时间
      var utcTime = len + offset
      var time = new Date(utcTime + 3600000 * i)
      // return time.getFullYear() + '-' + time.getMonth() + 1 +'-'+dd+' '+hh+':'+mm+':'+ss
      return `${time.getFullYear()}-${
        time.getMonth() + 1 < 10
          ? '0' + (time.getMonth() + 1)
          : time.getMonth() + 1
      }-${time.getDate() < 10 ? '0' + time.getDate() : time.getDate()}`
    }
  }
}
</script>

<style  scoped>
table.gridtable {
  width: 100%;
  font-family: verdana, arial, sans-serif;
  font-size: 15px;
  color: #333333;
  border-width: 1px;
  border-color: #666666;
  border-collapse: collapse;
}
table.gridtable th {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #fff;
}
table.gridtable th:nth-of-type(1) {
  background-color: #dedede;
}
table.gridtable td {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #ffffff;
}
.more {
  padding: 0 !important;
}
.more div {
  border-bottom: 1px solid #000;
  padding: 5px;
}
</style>
